@import "./src/assets/scss/main.scss";






















































































































































.cabinet {}

.container {}

.cabinet {
    margin-top: 85px;
    padding-bottom: 270px;
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
        padding-bottom: 110px;
        margin-top: 25px;
        flex-direction: column;
    }

    &__nav {
        width: 250px;
    }

    &__content {
        width: calc(100% - 326px);
        //background: #FFFFFF !important;
        box-shadow: -10px 6px 23px rgba(156, 156, 156, 0.1);
        border-radius: 12px;
        padding: 40px 20px 0px 49px;

        @include media-breakpoint-down(md) {
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
            padding-top: 20px;
        }

        .row {
            max-width: 700px;
        }
    }

    &__header {
        margin-bottom: 29px;
        font-size: 24px;
    }
}

.contacts {
    background: url(../assets/contacts-inner.svg) 100% -5% no-repeat #fff;
    padding-bottom: 35px;
    height: fit-content;

    @include media-breakpoint-down(md) {
        background: #fff;

    }

    label {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        padding-left: 30px;
        margin-bottom: 5px;

        @include media-breakpoint-down(md) {
            text-align: center;
            width: 100%;
            padding-left: 0;
        }
    }

    input {
        border: none;
        border-radius: 73px;
        height: 57px;
        font-family: Montserrat;
        color: #000000;
        padding-left: 29px;
        font-weight: 600;
        background: #F5F5F5 !important;
        font-size: 16px;
        //margin-bottom: 35px;

        @include media-breakpoint-down(md) {
            margin-bottom: 18px;
        }
    }

    &__btn {
        @include media-breakpoint-down(md) {
            display: flex;
            justify-content: center;
            margin-bottom: 35px;

            button {
                width: 100%;
            }
        }
    }
}

#app>div {

    background: url(../assets/contacts-outer.svg) 92% 70% no-repeat #F5F5F5;

    @include media-breakpoint-down(md) {
        background-size: contain;
    }
}

.footer {
    background: #fff;
}

.top-navbar {
    background: #fff !important;
}

.invalid-feedback {
    padding-left: 30px;
}
.vd-wrapper {
    max-width: 100%;
    margin-left: 0;
    margin-right: auto;
      background: #F5F5F5;
      border-radius: 73px;
      height: 57px !important;

}
.cabinet{
    input{
           height: 57px !important;
        background: #F5F5F5;
        border-color: red !important;
        
    }
}
